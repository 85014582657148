import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useFormatText } from "../../hooks/useFormatText";
import { useFetchImage } from "../../hooks/useFetchImage";

import Breadcrumb from "../../components/Breadcrumb";
import Banner from "../../components/Banner";
import Slider from "../../components/Slider";
import TableRef from "../../components/TableRef";
import Download from "../../components/Download";
import ProdAssociated from "../../components/ProdAssociated";

import "./style.css"

import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'
import TableRefMob from "../../components/TableRefMob";

function Product() {
    const { id } = useParams();
    const referenceRef = useRef(null);

    const goPage = useGoPage();
    const formatText = useFormatText();
    const fetchImage = useFetchImage();

    const [images, setImages] = useState([]);
    const [dataPage, setDataPage] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [dashboard, setDashboard] = useState(0);
    const [dashboardMob, setDashboardMob] = useState({
        ref: false,
        desc: false,
        down: false,
        ass: false
    });

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const token = useSelector(state => state.user.token);
    const products = useSelector(state => state.data.products);

    // Récupération de la largeur de l'écran
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Récupération des données de page
    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch(`https://api.pro-vide.eu/public/product/${lang}/${id}`);

            if (req.status !== 200) {
                goPage('error');
                return
            }

            const data = await req.json();
            setDataPage(data);

            if (data.references.data.length === 0) {
                setDashboard(1);
            }

            let newImages = [];

            for (let imgId of data.images) {
                if (imgId && imgId !== '') {
                    const result = await fetchImage(imgId);
                    newImages.push(result);
                }
            }

            setImages(newImages);
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, products, token]);

    // Ancre references
    const scrollToReferences = () => {
        if (dashboard !== 0) {
            setDashboard(0);
        }

        setTimeout(() => {
            const offset = 150;
            const referenceElement = referenceRef.current;
            const top = referenceElement.getBoundingClientRect().top + window.pageYOffset - offset;

            window.scrollTo({
                top: top,
                behavior: 'smooth'
            });
        }, 500);
    };


    return dataPage && images.length !== 0 && (
        <div className='page_product'>
            {windowWidth > 767 && <Breadcrumb data={dataPage} windowWidth={windowWidth} />}

            <div className="container_slider_banner">
                <Slider type='product' data={dataPage} images={images} childRoute='' windowWidth={windowWidth} scrollToReferences={scrollToReferences} />
                <Banner windowWidth={windowWidth} />
            </div>

            {(dataPage.features.length !== 0 || dataPage.benefits.length !== 0) &&
                <div className="features_and_benefits">
                    <div className="content">
                        {dataPage.features.length !== 0 &&
                            <ul className="features">
                                <li><h4>Caracteristiques</h4></li>

                                {dataPage.features.map((_, idx) => (
                                    <li key={idx}><p>{_}</p></li>
                                ))}
                            </ul>
                        }

                        {dataPage.benefits.length !== 0 &&
                            <ul className="benefits">
                                <li><h4>Avantages</h4></li>

                                {dataPage.benefits.map((_, idx) => (
                                    <li key={idx}><p>{_}</p></li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            }

            {dashboard !== undefined && windowWidth > 767 &&
                <div ref={referenceRef} className="dashboard">
                    <div className="onglets">
                        {dataPage.references.data.length !== 0 &&
                            <button className={dashboard === 0 ? 'actif' : ''} onClick={() => setDashboard(0)}>
                                {trad.onglet_table_ref[lang]}
                            </button>
                        }

                        <button className={dashboard === 1 ? 'actif' : ''} onClick={() => setDashboard(1)}>
                            {trad.onglet_description[lang]}
                        </button>

                        <button className={dashboard === 2 ? 'actif' : ''} onClick={() => setDashboard(2)}>
                            {trad.onglet_downloads_files[lang]}
                        </button>

                        <button className={dashboard === 3 ? 'actif' : ''} onClick={() => setDashboard(3)}>
                            {trad.onglet_prod_associated[lang]}
                        </button>
                    </div>

                    {dashboard === 0 && dataPage.references.data.length !== 0 && <TableRef data={dataPage} />}

                    {dashboard === 1 &&
                        <section className='sect_descrip'>
                            <div className="container_descrip">
                                <h4>Description</h4>
                                <p className='descrip'>{formatText(dataPage.text)}</p>
                            </div>
                        </section>
                    }

                    {dashboard === 2 && dataPage.files.length !== 0 && <Download data={dataPage} />}

                    {dashboard === 3 && dataPage.associated.length !== 0 && <ProdAssociated data={dataPage.associated} windowWidth={windowWidth} />}
                </div>
            }

            {dashboardMob && dataPage.references.length !== 0 && windowWidth < 766 &&
                <section className="sect_mobile">
                    <button className='btn_open_sect_mobile' onClick={() => setDashboardMob({...dashboardMob, ref: !dashboardMob.ref})}>
                        {trad.onglet_table_ref[lang]} <span className={dashboardMob.ref ? 'large' : ''}>{dashboardMob.ref ? '-' : '+'}</span>
                    </button>

                    {dashboardMob.ref && <TableRefMob data={dataPage} />}
                </section>
            }

            {dashboardMob && windowWidth < 766 &&
                <section className="sect_mobile">
                    <button className='btn_open_sect_mobile' onClick={() => setDashboardMob({...dashboardMob, desc: !dashboardMob.desc})}>
                        {trad.onglet_description[lang]} <span className={dashboardMob.desc ? 'large' : ''}>{dashboardMob.desc ? '-' : '+'}</span>
                    </button>

                    {dashboardMob.desc && <p className='descrip'>{formatText(dataPage.text)}</p>}
                </section>
            }

            {dashboardMob && windowWidth < 766 &&
                <section className="sect_mobile">
                    <button className='btn_open_sect_mobile' onClick={() => setDashboardMob({...dashboardMob, down: !dashboardMob.down})}>
                        {trad.onglet_downloads_files[lang]} <span className={dashboardMob.down ? 'large' : ''}>{dashboardMob.down ? '-' : '+'}</span>
                    </button>
                </section>
            }

            {dashboardMob && windowWidth < 766 &&
                <section className="sect_mobile">
                    <button className='btn_open_sect_mobile' onClick={() => setDashboardMob({...dashboardMob, ass: !dashboardMob.ass})}>
                        {trad.onglet_prod_associated[lang]} <span className={dashboardMob.ass ? 'large' : ''}>{dashboardMob.ass ? '-' : '+'}</span>
                    </button>

                    {dashboardMob.ass && <ProdAssociated data={dataPage.associated} windowWidth={windowWidth} />}
                </section>
            }

            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{trad.msg_bottom_contact[lang]}</p>

                <div className="container_btn_contact">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" />+33 2 55 99 65 05</a>
                    <button onClick={() => goPage('contact')} className="btn-email"><img src={icon_message} alt="Icon" />{trad.btn_bottom_contact[lang]}</button>
                </div>
            </div>
        </div>
    )
}

export default Product;