import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFormatText } from "../../hooks/useFormatText";
import { useGoPage } from "../../hooks/useGoPage";

import SliderImg from "../SliderImg";
import RequestPOR from "../../modals/RequestPOR";

import "./style.css"

function Slider({ type, data, images, childRoute, windowWidth, scrollToReferences }) {
    const goPage = useGoPage();
    const formatText = useFormatText();

    const [counter, setCounter] = useState(0);
    const [requestSpe, showRequestSpe] = useState(false);

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    // Counter slider
    useEffect(() => {
        if (type !== 'category') {
            return
        }

        const intervalId = setInterval(() => {
            setCounter(prev => prev === images.length - 1 ? 0 : prev + 1);
        }, 6950);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, images]);


    return (
        <div className={`slider_${type}`}>
            {type === 'category' &&
                <>
                    <div className="content">
                        <div className="descrip">
                            <h2>{data[counter].name}</h2>
                            {data[counter].text && data[counter].text !== ' ' && <p>{formatText(data[counter].text)}</p>}
                            <button className='btn_show_more' onClick={() => goPage(`${childRoute}/${data[counter]._id}`)}>
                                {trad.btn_show_more[lang]}
                            </button>
                        </div>


                        <div className={`images img_${childRoute}`}>
                            {childRoute === 'category' && images[counter] &&
                                <img src={images[counter][`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' />
                            }

                            {(childRoute === 'product' || childRoute === 'service') &&  images[counter] && images[counter][0] &&
                                <div><img src={images[counter][0][`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' /></div>
                            }
                        </div>
                    </div>

                    <div className="dots">
                        {data.map((e, idx) => (
                            <i className={`dot ${idx === counter ? 'dot_actif' : ''}`} key={idx}></i>
                        ))}
                    </div>
                </>
            }

            {type === 'product' &&
                <div className="content">
                    <div className="descrip">
                        <h2>{data.name}</h2>
                        <p>{formatText(data.text)}</p>

                        {data.references && <button onClick={scrollToReferences} className='btn_show_more'>{trad.btn_show_reference[lang]}</button>}
                        {!data.references && <button onClick={() => showRequestSpe(true)} className='btn_request_spe'>{trad.btn_make_request[lang]}</button>}
                    </div>

                    <SliderImg type={type} data={images} windowWidth={windowWidth} />
                </div>
            }

            {requestSpe && <RequestPOR elementId={data._id} type='prod' qte='1' showMdl={showRequestSpe} />}
        </div>
    )
}

export default Slider;